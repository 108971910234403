<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP ESTIMATE VIEW

type    : view

uses    : inventory-map
          work-task-sections-list (when able to be edited)
          work-task-sections-list-readonly (when locked)
          header-view
          main-container
          main-card

route   : /estimates/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-estimate-view" v-if="componentCreated">
    <header-view
      title="Build Estimate"
      short_title=""
      :btnOptions="headerButtons"
      :id="'EST-' + estimate.number.toString().padStart(5, '0')"
      :status="estimate.status"
      :job_uuid="estimate.job_uuid"
      view_phase="Estimate"
      :previous_page="'/jobs/view/' + estimate.job_uuid" />
    <main-container v-if="estimate">
      <main-card>
        <v-row>
          <v-col cols="12" md="4" class="px-2">
            <div class="title">Client Information</div>
            <router-link :to="'/clients/view/' + client.uuid">
              <div class="subheading">{{ client.client_name }}</div>
            </router-link>
            <div class="subheading">{{ client.contact_name }}</div>
            <div class="subheading">{{ client.contact_phone }}</div>
            <div class="subheading">{{ client.contact_email }}</div>
            <v-row>
              <v-col md="12">
                <b
                  >Est.
                  {{
                    displayDateFormat(
                      estimate.date_created,
                      tenantSettings.date_format
                    )
                  }}</b
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" class="px-2">
            <div class="title">Job Site</div>
            <div class="subheading">{{ jobSiteName }}</div>
            <div class="subheading">{{ jobsite.address }}</div>
            <div class="subheading">
              {{ jobsite.city }}, {{ jobsite.state }} {{ jobsite.zip_code }}
            </div>
            <div class="title mt-3">Crew Notes</div>
            <div v-if="!editingEstimateNotes">
              <span id="notesText">{{
                estimate.notes || 'None Available'
              }}</span>
              <v-btn
                class="mt-n1 ml-1"
                color="secondary"
                icon
                tile
                :ripple="false"
                x-small
                @click="editingEstimateNotes = true"
                :disabled="estimateLocked">
                <v-icon color="secondary">mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-textarea
                outlined
                :value="estimate.notes"
                :disabled="estimateLocked"
                @change="(value) => (newNotes = value)"></v-textarea>
              <v-btn
                color="button-primary"
                @click="
                  () => {
                    estimate.notes = newNotes;
                    editingEstimateNotes = false;
                    updateCrewNotes();
                  }
                ">
                Save
              </v-btn>
              <v-btn
                color="button-secondary"
                @click="
                  editingEstimateNotes = false;
                  newNotes = '';
                ">
                Cancel
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="px-2">
            <span class="title">Estimator</span>
            <v-select
              :items="usersSelectOptions"
              label="Assign Estimator"
              v-model="selectedEstimator"
              @change="assignEstimator"
              :disabled="estimateLocked"></v-select>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
              :disabled="estimateLocked">
              <template v-slot:activator="{on}">
                <v-text-field
                  v-model="displayedDueDate"
                  label="Select Due Date"
                  v-on="on"
                  id="due_date"
                  readonly
                  :disabled="estimateLocked" />
              </template>
              <v-date-picker
                v-model="estimate.due_date"
                no-title
                scrollable
                actions
                :allowed-dates="allowedDates"
                @change="assignDueDate"
                @input="menu = false">
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </main-card>
      <v-row class="mx-3" id="estimateDataRow">
        <v-col cols="12" class="mapToggleSwitch">
          <v-btn-toggle v-model="tableView" mandatory color="primary">
            <v-btn
              :color="!tableView ? '#035185' : ''"
              :style="
                !tableView ? 'border: 3px solid #004370 !important;' : ''
              ">
              <v-icon :color="!tableView ? 'white' : ''">mdi-map-marker</v-icon>
            </v-btn>
            <v-btn
              :color="tableView ? '#035185' : ''"
              :style="tableView ? 'border: 3px solid #004370 !important;' : ''">
              <v-icon :color="tableView ? 'white' : ''"
                >mdi-format-list-bulleted</v-icon
              >
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <!-- table view toggle : work-task-sections-list -->
        <v-col v-show="tableView" cols="12" v-if="tenantSettings">
          <!-- worktasksloaded is the key to enabling or disabling the preview estimate
         button. The component work-task-sections-list will trigger it by loading the worktasks
       and emmitting the result back to this component. -->
          <work-task-sections-list
            v-if="!estimateLocked"
            enable_openbid
            enable_catalogs
            :readonly="estimateLocked"
            :enable_controls="true"
            v-bind:estimate="estimate"
            v-bind:tenantSettings="tenantSettings"
            v-bind:trees="trees"
            v-on:worktasksloaded="toggleProposalVisibility"
            @totalpriceupdate="updateTotal" />

          <work-task-sections-list-readonly
            v-else
            v-bind:estimate="estimate"
            v-bind:tenantSettings="tenantSettings"
            v-bind:trees="trees"
            v-on:worktasksloaded="toggleProposalVisibility"
            @totalpriceupdate="updateTotal" />
        </v-col>

        <!-- map view toggle: inventory-map -->
        <v-col cols="12" v-show="!tableView">
          <v-subheader
            >Click on the map to add tree location and information.</v-subheader
          >
        </v-col>
        <v-col cols="12" v-show="!tableView">
          <inventory-map
            v-bind:trees="trees"
            v-bind:jobsites="[jobsite]"
            v-bind:tenantSettings="tenantSettings"
            v-bind:selected_jobsite_uuid="selectedJobSiteUuid"
            :can_add_trees="true"
            inventory_type="trees"
            v-bind:client="client"
            v-bind:estimate="estimate"
            ref="inventoryMap" />
        </v-col>
      </v-row>
    </main-container>

    <v-snackbar v-model="savedSRProperlyDialog" :timeout="5000">
      Estimate successfully updated, thank you.
      <v-btn color="blue" text @click="savedSRProperlyDialog = false">
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog
      class="inventory-map-estimate-revisions-dialog"
      v-model="viewEstimateRevisions"
      width="500">
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Past Estimate Revisions"
          closeBtn
          :closeAction="
            () => {
              viewEstimateRevisions = false;
            }
          " />

        <v-card-text>
          <div
            class="subheading ma-3"
            v-for="item in estimate.revision_comments"
            v-bind:key="item">
            {{ item }}
            <v-divider></v-divider>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // components
  import InventoryMap from '@/components/inventory-map';
  import WorkTaskSectionsList from '@/components/worktasks/work-task-sections-list';
  import WorkTaskSectionsListReadonly from '../../components/worktasks/work-task-sections-list-readonly.vue';
  import MainCard from '@/components/main-card';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';

  // mixins

  import Localization from '@/mixins/localization';
  import email from '@/mixins/email';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';
  import Forms from '@/mixins/forms';

  // services
  import Tenants from '@/services/Tenants.service.js';
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';
  import Users from '@/services/Users.service.js';

  export default {
    name: 'AppEstimateView',
    components: {
      'inventory-map': InventoryMap,
      'work-task-sections-list': WorkTaskSectionsList,
      'work-task-sections-list-readonly': WorkTaskSectionsListReadonly,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
    },
    mixins: [email, dateTimeHelpers, Forms, Localization],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        catalog: null,
        client: null,
        componentCreated: false,
        editingEstimateNotes: false,
        editingJobSites: false,
        estimate: null,
        jobsite: null,
        loading4: false,
        menu: false,
        newJobSite: {},
        newJobSiteDialog: false,
        newNotes: '',
        possible_job_sites: [],
        proposalVisible: false,
        savedSRProperlyDialog: false,
        saveJobSiteConfirm: false,
        selectedEstimator: undefined,
        selectedJobSiteUuid: '',
        selectedStatus: 'Pending',
        service_request_uuid: '',
        submitted: false,
        tableView: 0,
        trees: [],
        usersSelectOptions: [],
        viewEstimateRevisions: false,
        viewJobDetails: false,
        catalog_selects: [
          {
            value: 'PHC',
            text: 'PHC Catalog',
          },
          {
            value: 'QUICKPRICE',
            text: 'Quickprice Catalog',
          },
        ],
        workTasksHeaders: [
          {
            text: 'Task',
            align: 'left',
            value: 'task',
          },
          {
            text: 'Cost',
            value: 'cost',
          },
          {
            text: 'Hours',
            value: 'hours',
          },
        ],
        tenantSettings: {},
      };
    },
    computed: {
      jobSiteName() {
        return this.jobsite && this.jobsite.name
          ? this.jobsite.name
          : 'No Site Name';
      },
      displayedDueDate() {
        if (this.estimate.due_date) {
          const due_date = this.displayDateFormat(
            this.estimate.due_date,
            this.tenantSettings.date_format
          );
          return due_date;
        } else {
          return undefined;
        }
      },
      estimateLocked() {
        return (
          this.estimate.status == 'Approved' ||
          this.estimate.status == 'Sent' ||
          this.estimate.status == 'Declined'
        );
      },
      switchLabel() {
        if (this.tableView) {
          return 'Table';
        } else {
          return 'Map';
        }
      },
      headerButtons() {
        const buttons = [
          {
            name: 'Preview Estimate',
            btnColor: 'button-primary',
            disabled: !this.proposalVisible,
            action: () => {
              this.$router
                .push({
                  name: 'EstimateProposal',
                  params: {
                    uuid: this.estimate.uuid,
                  },
                })
                .catch((err) => {
                  console.log(err);
                });
            },
          },
        ];

        if (
          this.estimate.revision_comments &&
          this.estimate.revision_comments.length > 0
        ) {
          buttons.unshift({
            name: 'View Revisions',
            btnColor: 'button-secondary',
            action: () => {
              this.viewEstimateRevisions = true;
            },
          });
        }

        return buttons;
      },
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
      tableView: function () {
        if (!this.tableView) {
          this.$refs.inventoryMap.renderTreesMap();
        }
      },
    },
    async created() {
      // console.log(" ---- AppEstimateView ---- ");

      const accessToken = await this.$auth.getTokenSilently();

      // get estimate
      const res_estimate = await Jobs.getEstimate(this.uuid, accessToken);

      if (res_estimate) {
        this.estimate = res_estimate;

        // get tenant settings
        // (in an authenticated situation we already have this info
        // via tenantProfile that gets loaded in $auth during the
        // begging of the user's session. This special case
        // is needed for the connected components that need this info
        // passed in for unsecured estimate proposal links)
        this.tenantSettings = this.$auth.tenantProfile
          ? this.$auth.tenantProfile
          : await Tenants.getSettingsInfo(
              this.$auth.userProfile.tenant_uuid
              // unsecured route
            );

        // console.log("tenantSettings:", this.tenantSettings);

        // get estimator info
        if (res_estimate.estimator_uuid) {
          this.selectedEstimator = res_estimate.estimator_uuid;
        }

        //get client
        this.client = await Clients.getClient(
          res_estimate.client_uuid,
          accessToken
        );

        // get job site
        const res_jobsite = await Clients.getJobSite(
          res_estimate.job_site_uuid,
          accessToken
        );

        if (res_jobsite) {
          this.jobsite = res_jobsite;
          this.selectedJobSiteUuid = this.jobsite.uuid;
        }

        //load all job sites for client
        const res_all_jobsites = await Clients.getJobSites(
          {
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
            client_uuid: res_estimate.client_uuid,
          },
          accessToken
        );

        //TLC note, this is async, but we are not waiting for the promises to resolve
        this.possible_job_sites = res_all_jobsites.map((site) => {
          return {
            text: site.address + ', ' + site.city + ', ' + site.state,
            value: site.uuid,
          };
        });

        //load the trees belonging to this job site
        const res_trees = await Clients.getTreesByJobSite(
          res_estimate.job_site_uuid
        );

        if (res_trees) {
          this.trees = res_trees;
          this.trees = await Jobs.getWorkTaskCountByTree(
            res_estimate.uuid,
            res_trees
          );
        }

        //get users list for assigning the estimate
        const res_users = await Users.getUsersByTenant(
          res_estimate.tenant_uuid,
          accessToken
        );

        //TLC note, this is async, but we are not waiting for the promises to resolve
        this.usersSelectOptions = res_users
          .map((x) => {
            return {
              text: x.name,
              value: x.uuid,
              active: x.status != 'Inactive',
            };
          })
          .filter((x) => x.active);

        this.$events.$on('onTreesChanged', this.updateTrees);
        this.$events.$on('workTasksCountChanged', this.onWorkTasksCountChanged);
        this.componentCreated = true;
      }
    },
    methods: {
      toggleProposalVisibility(worktasks) {
        if (worktasks && worktasks.length > 0) {
          this.proposalVisible = true;
          return true;
        } else {
          this.proposalVisible = false;
          return false;
        }
      },
      async handleNewJobSiteSubmit() {
        this.loader = 'loading4';
        if (!this.$refs.form.validate()) {
          return false;
        }
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        const newJobSite = {
          uuid: Jobs.newJobSiteUUID(),
          address: this.newJobSite.address,
          city: this.newJobSite.city,
          state: this.newJobSite.state,
          zip_code: this.newJobSite.zip_code,
          country: this.newJobSite.country,
          client_uuid: this.client.uuid,
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
        };

        // send request to the api service
        const res = await Clients.createJobSite(newJobSite, accessToken);

        if (res) {
          this.submitted = true;
          this.selectedJobSiteUuid = res.uuid;
          this.jobSiteChanged();
        } else {
          this.errorMessage = 'something went wrong';
          // TODO: fix this use of $nextTick. It's probably a mistake
          // since it doesn't have a callback.
          // eslint-disable-next-line vue/valid-next-tick
          this.$nextTick();
        }
      },
      async updateTrees() {
        const res_trees = await Clients.getTreesByJobSite(
          this.estimate.job_site_uuid
        );

        if (res_trees) {
          this.trees = await Jobs.getWorkTaskCountByTree(
            this.estimate.uuid,
            res_trees
          );
        }
      },
      allowedDates: function (val) {
        return new Date().toISOString().substr(0, 10) <= val;
      },
      async assignEstimator() {
        const res = await Jobs.updateEstimate(
          this.estimate.uuid,
          {
            estimator_uuid: this.selectedEstimator,
          }
          // unsecured route
        );

        if (res) {
          // update estimate
          this.estimate.estimator_uuid = this.selectedEstimator;
          this.savedSRProperlyDialog = true;
          // disabled until event creation is vetted and assigned
          // this.createEvent({
          //   link: window.location.href,
          //   users: [this.selectedEstimator],
          //   notification_name: 'ESTIMATE-ASSIGNED',
          //   clientUuid: this.client.uuid,
          // });
        } else {
          throw Error('Something went wrong');
        }
      },
      async assignDueDate() {
        // update estimate
        const res = await Jobs.updateEstimate(
          this.estimate.uuid,
          {
            due_date: this.estimate.due_date,
          }
          // unsecured route
        );

        if (res) {
          // confirmation dialog
          this.savedSRProperlyDialog = true;
        } else {
          throw Error('Something went wrong');
        }
      },
      async updateCrewNotes() {
        await Jobs.updateEstimate(
          this.estimate.uuid,
          this.estimate
          // unsecured route
        );
      },
      async updateTotal(total) {
        if (this.estimate.total_price != total) {
          // update local estimate total
          this.estimate.total_price = total;

          // update estimate
          await Jobs.updateEstimate(
            this.estimate.uuid,
            {
              total_price: total,
            }
            // unsecured route
          );
        }
      },
      async jobSiteChanged() {
        const res = await Jobs.updateEstimate(
          this.estimate.uuid,
          {
            job_site_uuid: this.selectedJobSiteUuid,
          }
          // unsecured route
        );

        if (res) {
          this.saveJobSiteConfirm = true;
        }
      },
      newJobSiteDone() {
        this.$router.go(0);
      },
      async onWorkTasksCountChanged() {
        this.trees = await Jobs.getWorkTaskCountByTree(
          this.estimate.uuid,
          this.trees
        );
        if (!this.tableView) {
          this.$refs.inventoryMap.renderTreesMap();
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
  #estimateHeaderRow {
    min-height: 12em;
  }
  .mapToggleSwitch {
    display: block;
  }
  .btn-active {
    color: white !important;
    background-color: #004370 !important;
  }
</style>
